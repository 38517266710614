import './App.scss';
import { YearSelect } from './components/YearSelect';
import { Calculator } from './components/Calculator';
import { useState, useEffect } from 'react';
import { ITaxData } from './interfaces/ITaxData';
import taxDataJson from './json/taxData.json';

export const App = () => {
  const years: number[] = taxDataJson.taxYears.map(element => element.year);
  const [year, setYear] = useState(years[0] || 0);
  const [taxData, setTaxData] = useState<ITaxData>();
  
  const handleYearChange = (selectedYear: number) => {
    setYear(selectedYear);
  };

  useEffect(() => {
    if (year === 0) return;
    let data = taxDataJson.taxYears.find(taxYear => taxYear.year === year) as ITaxData
    if (data === undefined) return;
    setTaxData(data);
  }, [year]);

  return (
    <div className="calculation-container">
      <div className='title-row'>
        <h2>Box 3 calculator</h2>
        <YearSelect years={years} handleYearChange={handleYearChange} />
      </div>
      {taxData && <Calculator taxData={taxData} />}
    </div>
  );
}
