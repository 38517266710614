import './YearSelect.scss';

interface IProps {
  years: number[];
  handleYearChange: (selectedYear: number) => void;
}

export const YearSelect = (props: IProps) => {

  return (
    <>
      <select onChange={(event) => props.handleYearChange(parseInt(event.target.value))}>
        {props.years.map(year => (<option key={year}>{year}</option>))}
      </select>
    </>
  )
}
