import { useState, useEffect } from "react";
import { ITaxData } from "../interfaces/ITaxData";
import './Calculator.scss';

interface IProps {
  taxData: ITaxData;
}

export const Calculator = (props: IProps) => {
  const [savingsInput, setSavingsInput] = useState<string>();
  const [debtInput, setDebtInput] = useState<string>();
  const [propertyInput, setPropertyInput] = useState<string>();
  const [actualEfficiencyInput, setActualEfficiencyInput] = useState<string>();
  const [fiscalPartner, setFiscalPartner] = useState<boolean>(false);

  const [taxDataContainsDisks, setTaxDataContainsDisks] = useState<boolean>(false);

  const [totalEfficiencyOld, setTotalEfficiencyOld] = useState(0);
  const [totalEfficiencyRedress, setTotalEfficiencyRedress] = useState(0);

  const [taxTotalOld, setTaxTotalOld] = useState(0);
  const [taxTotalRedress, setTaxTotalRedress] = useState(0);
  const [taxTotalActual, setTaxTotalActual] = useState(0);
  const [fiscalBenefitAfterRedress, setFiscalBenefitAfterRedress] = useState(0);

  const [calculated, setCalculated] = useState(false);

  const { taxData } = props;

  useEffect(() => {
    setTaxDataContainsDisks(false);

    setTotalEfficiencyOld(0);
    setTotalEfficiencyRedress(0);

    setTaxTotalOld(0);
    setTaxTotalRedress(0);
    setTaxTotalActual(0);
    setFiscalBenefitAfterRedress(0);

    setCalculated(false);
  }, [taxData]);

  /** 
   * Removes all non-numeric characters from a monetary value.
   * 
   * @param value The raw monetary input string.
   * @returns A string that only contains the numeric characters of the input value, or an empty string if the input value is undefined.
   */
  const purgeMonetaryValue = (value?: string) => {
    if (!value) return "";

    const cleanValue = value.replace(/[^0-9]/g, "");

    return cleanValue;
  }

  /** 
   * Formats a monetary value by applying thousands separators.
   * 
   * @remark This method is culture-agnostic and will always use a period (.) as thousands separator.
   * 
   * @param value The raw monetary input string.
   * @returns A string containing only numeric characters and thousands separators.
   */
  const formatMonetaryValue = (value: string | number) => {
    const cleanValue = purgeMonetaryValue(value.toString());
    const separatedValue = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return separatedValue;
  }

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    var rawValue = event.currentTarget.value;
    var formattedValue = formatMonetaryValue(rawValue);

    setter(formattedValue);
  }

  const handleCalculation = () => {
    const savings = parseInt(purgeMonetaryValue(savingsInput)) || 0;
    const debt = parseInt(purgeMonetaryValue(debtInput)) || 0;
    const property = parseInt(purgeMonetaryValue(propertyInput)) || 0;
    const actualEfficiency = parseInt(purgeMonetaryValue(actualEfficiencyInput)) || 0;

    if (savings === 0 && debt === 0 && property === 0 && actualEfficiency === 0) {
      setCalculated(false);
      return;
    }

    let debtThresholdAmount = fiscalPartner ? taxData.debtThresholdAmount * 2 : taxData.debtThresholdAmount;
    let taxFreeAmount = fiscalPartner ? taxData.taxFreeAmount * 2 : taxData.taxFreeAmount;

    let debtAfterThreshold = debt! - debtThresholdAmount > 0 ? debt! - debtThresholdAmount : 0;
    let totalCapital = savings! - debtAfterThreshold + property!;
    let totalTaxableCapital = totalCapital - taxFreeAmount;
    totalTaxableCapital = totalTaxableCapital > 0 ? totalTaxableCapital : 0;

    const taxDataContainsDisksLocal = taxData.diskLowerLimit !== undefined && taxData.diskUpperLimit !== undefined
      && taxData.diskLowerEfficiencyPercentage !== undefined && taxData.diskUpperEfficiencyPercentage !== undefined;
    setTaxDataContainsDisks(taxDataContainsDisksLocal);

    // Calculation for old taxation system based on disks
    let efficiencyTaxTotalOld = 0;
    if (taxDataContainsDisksLocal) {
      let diskLowerLimit = taxData.diskLowerLimit! - taxFreeAmount;
      let diskUpperLimit = taxData.diskUpperLimit! - taxFreeAmount;

      let lowerDiskCapital = Math.min(totalTaxableCapital, diskLowerLimit);
      let middleDiskCapital = Math.min(totalTaxableCapital - diskLowerLimit > 0 ? totalTaxableCapital - diskLowerLimit : 0,
        diskUpperLimit - diskLowerLimit);
      let upperDiskCapital = totalTaxableCapital - diskUpperLimit > 0 ? totalTaxableCapital - diskUpperLimit : 0;

      let lowerDiskEfficiency = 0.67 * lowerDiskCapital * taxData.diskLowerEfficiencyPercentage! * 0.01 + 0.33 * lowerDiskCapital * taxData.diskUpperEfficiencyPercentage! * 0.01;
      let middleDiskEfficiency = 0.21 * middleDiskCapital * taxData.diskLowerEfficiencyPercentage! * 0.01 + 0.79 * middleDiskCapital * taxData.diskUpperEfficiencyPercentage! * 0.01;
      let upperDiskEfficiency = upperDiskCapital * taxData.diskUpperEfficiencyPercentage! * 0.01;
      let efficiencyTotalOld = Math.floor(lowerDiskEfficiency + middleDiskEfficiency + upperDiskEfficiency);
      efficiencyTaxTotalOld = Math.floor(efficiencyTotalOld * taxData.incomeTaxPercentage * 0.01);
      setTotalEfficiencyOld(efficiencyTotalOld);
      setTaxTotalOld(efficiencyTaxTotalOld);
    }

    // Redress calculation
    let efficiencySavingsRedress = Math.floor(savings! * (taxData.lumpsumSavingsPercentage * 0.01));
    let efficiencyDebtRedress = Math.ceil(debtAfterThreshold * (taxData.lumpsumDebtPercentage * 0.01));
    let efficiencyPropertyRedress = Math.floor(property! * (taxData.lumpsumPropertyPercentage * 0.01));
    let efficiencyTotalRedressExcludingTaxFree = efficiencySavingsRedress - efficiencyDebtRedress + efficiencyPropertyRedress;
    efficiencyTotalRedressExcludingTaxFree = efficiencyTotalRedressExcludingTaxFree > 0 ? efficiencyTotalRedressExcludingTaxFree : 0;
    let AverageEfficiency = Math.floor((efficiencyTotalRedressExcludingTaxFree / (totalCapital)) * 10000) / 10000;
    let efficiencyTotalRedress = Math.floor(totalTaxableCapital * AverageEfficiency);
    let calculatedTaxTotalRedress = Math.floor(efficiencyTotalRedress * (taxData.incomeTaxPercentage * 0.01));
    setTotalEfficiencyRedress(efficiencyTotalRedress);
    setTaxTotalRedress(calculatedTaxTotalRedress);

    // Calculation based on actual efficiency
    let efficiencyTaxTotalActual = Math.floor(actualEfficiency * (taxData.incomeTaxPercentage * 0.01));
    setTaxTotalActual(efficiencyTaxTotalActual);

    let fiscalBenefit = taxDataContainsDisksLocal ? efficiencyTaxTotalOld - calculatedTaxTotalRedress : 0;
    setFiscalBenefitAfterRedress(fiscalBenefit || 0);

    setCalculated(true);
  };

  return (
    <>
      <div className="input-block">
        <div className="calculator-row">
          <label htmlFor="savings">Spaargeld</label>
          <input type="text" pattern="[0-9,.]*" id="savings" value={savingsInput !== undefined ? savingsInput : ""} onChange={e => handleInput(e, setSavingsInput)}></input>
        </div>
        <div className="calculator-row">
          <label htmlFor="property">Overige bezittingen</label>
          <input type="text" pattern="[0-9,.]*" id="property" value={propertyInput !== undefined ? propertyInput : ""} onChange={e => handleInput(e, setPropertyInput)}></input>
        </div>
        <div className="calculator-row">
          <label htmlFor="debt">Schulden</label>
          <input type="text" pattern="[0-9,.]*" id="debt" value={debtInput !== undefined ? debtInput : ""} onChange={e => handleInput(e, setDebtInput)}></input>
        </div>
        <div className="calculator-row">
          <label htmlFor="actualEfficiency">Werkelijk rendement</label>
          <input type="text" pattern="[0-9,.]*" id="actualEfficiency" value={actualEfficiencyInput !== undefined ? actualEfficiencyInput : ""} onChange={e => handleInput(e, setActualEfficiencyInput)}></input>
        </div>
        <div className="calculator-row">
          <label htmlFor="fiscalPartner">Fiscaal partner</label>
          <input type="checkbox" pattern="[0-9,.]*" id="fiscalPartner" checked={fiscalPartner} onChange={() => setFiscalPartner(!fiscalPartner)}></input>
        </div>
      </div>

      <div className="calculation-block">
        <button onClick={() => handleCalculation()}>Bereken</button>
        {calculated ?
          taxDataContainsDisks ?
            taxTotalRedress < taxTotalOld && taxTotalRedress < taxTotalActual ?
              <span>Fiscaal voordeel bij heffing op basis van <b className="positive">rechtsherstel</b>: € {formatMonetaryValue(Math.round(fiscalBenefitAfterRedress))},-</span>
              :
              taxTotalActual < taxTotalOld ?
                <span>Fiscaal voordeel bij heffing op basis van <b className="positive">werkelijk rendement</b>: € {formatMonetaryValue(Math.round(taxTotalOld - taxTotalActual))},-</span>
                :
                <span>Heffing op basis van oorspronkelijk box 3-systeem fiscaal meest voordelig.</span>
            :
            taxTotalActual < taxTotalRedress ?
              <span>Fiscaal voordeel bij heffing op basis van <b className="positive">werkelijk rendement</b>: € {formatMonetaryValue(Math.round(taxTotalRedress - taxTotalActual))},-</span>
              :
              <span>Heffing op basis van overbruggingssysteem fiscaal meest voordelig.</span>
          :
          <></>
        }
      </div>
    </>
  )
}
